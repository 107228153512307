import React from 'react'

import Layout, { ExperimentalFeatures } from '../components/layout'

export default function Courses(): JSX.Element {
  return (
    <Layout>
      <ExperimentalFeatures.Consumer>
        {(value) => {
          if (value === true) {
            return <div />
          }
          return (
            <div id="courses">
              <h4>
                The Courses Page is currently undergoing reconstruction. We hope to get this page
                back up as soon as possible
              </h4>
            </div>
          )
        }}
      </ExperimentalFeatures.Consumer>
    </Layout>
  )
}
